import React, { useEffect, useRef, useState } from 'react'
import VerticalSpacer from '../../components/VerticalSpacer'
import ModularButton from '../../components/ModularButton'
import * as TimeLogifyAPI from '../../mgt/timeLogifyAPI'
import { useGlobals } from '../../mgt/GlobalsProvider'
import { modifyUserDocument } from '../../mgt/firestoreAPI'
import { hexToRgbForHTML } from '../../mgt/constants'

const General = () => {
    const [autoStartEnabled, setAutoStartEnabled] = useState(false)
    const { userDoc, setUserDoc } = useGlobals()
    const [color, setColor] = useState('#00BCD4');
    const colorBtnRef = useRef(null);

    async function toggleAutoStart() {
        if (TimeLogifyAPI.setAutoStartEnabled(!autoStartEnabled)) {
            setAutoStartEnabled(!autoStartEnabled)
        }

        const newUserDoc = { ...userDoc }
        userDoc.settings.general.autoStart = !autoStartEnabled
        setUserDoc(newUserDoc)
        modifyUserDocument(newUserDoc)
    }

    async function saveAccentColor(color) {
        const newUserDoc = { ...userDoc }
        userDoc.settings.general.accentColor = color
        setUserDoc(newUserDoc)
        modifyUserDocument(newUserDoc)

        document.documentElement.style.setProperty('--accent-color', color)
        document.documentElement.style.setProperty('--accent-color-rgb', hexToRgbForHTML(color))
    }

    useEffect(() => {
        if (!userDoc) return

        setAutoStartEnabled(userDoc.settings.general.autoStart)
        setColor(userDoc.settings.general.accentColor)
    }, [userDoc])

    return <>
        <VerticalSpacer size={5} />

        <div className='settingsTitle'>Automatically start logging</div>
        <div className='settingsSubtitle'>Enable this to automatically start logging when you log into your computer.</div>

        <VerticalSpacer size={1} />

        <ModularButton
            title={autoStartEnabled ? 'Enabled' : 'Disabled'}
            border={true}
            onClick={toggleAutoStart}
            style={{
                color: autoStartEnabled ? '#4caf50' : 'var(--text-color-subtle)',
                borderColor: autoStartEnabled ? '#4caf50' : 'var(--lighter-border)',
                marginLeft: '5rem'
            }}
        />

        <VerticalSpacer size={2} />

        <div className='settingsTitle'>Change accent color</div>
        <div className='settingsSubtitle'>Choose the accent color used throughout the website</div>

        <VerticalSpacer size={1} />

        <div style={{
            background: color,
            marginLeft: '5rem',
            overflow: 'hidden',
            borderRadius: '1rem',
        }}>
            <input
                type="color"
                value={color}
                ref={colorBtnRef}
                style={{
                    filter: 'opacity(0)',
                    width: '10rem',
                    height: '2.5rem',
                    cursor: 'pointer',
                }}
                aria-label="Choose accent color"
                onChange={(e) => setColor(e.target.value)}
                onBlur={(e) => saveAccentColor(e.target.value)}
            />
        </div>
    </>
}

export default General