import * as timeLogifyAPI from './timeLogifyAPI.js';

function formatDate(date) {
    const pad = (num) => num.toString().padStart(2, '0');

    // Get components of the date
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -date.getTimezoneOffset();
    const timezoneHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
    const timezoneMinutes = pad(Math.abs(timezoneOffset) % 60);
    const timezoneSign = timezoneOffset >= 0 ? '+' : '-';

    const dateStr = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${timezoneSign}${timezoneHours}${timezoneMinutes}`;
    console.log('Date:', dateStr)
    return dateStr
}

export function formatTimeSpent(timeSpent) {
    const days = Math.floor(timeSpent / 480)
    const hours = Math.floor((timeSpent % 480) / 60)
    const minutes = timeSpent % 60
    let str = ''

    if (days > 0) str += `${days}d`
    if (hours > 0) str += ` ${hours}h`
    if (minutes > 0) str += ` ${minutes}m`

    if (str === '') str = '0m'

    return str
}

export async function logWorkToJira(issueKey, comment, userDoc, date, timeSpent) {
    const result = await timeLogifyAPI.logWorkToJira(issueKey, comment, userDoc, formatDate(date), timeSpent)
    return result
}

export async function getIssueTimeSpent(issueKeys, userDoc, date) {
    const data = await timeLogifyAPI.getJiraTimeSpent(issueKeys, userDoc, date)
    return data
}
