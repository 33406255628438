// Imports
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../mgt/firebase';
import * as TimeLogifyAPI from '../mgt/timeLogifyAPI';
import ModularButton from '../components/ModularButton';
import { DownArrowsIcon, DownloadIcon, RefreshIcon, RightArrowsIcon } from '../components/Icons';
import { downloadUrl, releaseNotes } from '../mgt/constants';
import HeaderBar from '../components/HeaderBar';
import VerticalSpacer from '../components/VerticalSpacer'
import LoadingText from '../components/LoadingText';


function UpdateWizard() {
    // Authentication
    const [user, loading] = useAuthState(auth)
    const [requestedVersion, setRequestedVersion] = useState('')

    // Navigation
    const navigate = useNavigate();
    const [wizardStep, setWizardStep] = useState(1)
    const wizardRef1 = useRef(null)
    const wizardRef2 = useRef(null)
    const wizardSteps = [wizardRef1, wizardRef2]

    // TimeLogify Status
    const [installerDownloaded, setInstallerDownloaded] = useState(false)
    const [checkVersionIntervalActive, setCheckVersionIntervalActive] = useState(false)
    const [timeLogifyUpdated, setTimeLogifyUpdated] = useState(false)


    // UseEffects
    useEffect(() => {
        if (loading) return
        if (!user) {
            navigate('/')
            return
        }

        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(new URL(currentUrl).search);
        const v = urlParams.get('v');
        if (!v) {
            navigate('/')
            return
        }

        setRequestedVersion(v)
    }, [loading, user])

    useEffect(() => {
        let interval

        if (checkVersionIntervalActive) {
            interval = setInterval(() => {
                if (timeLogifyUpdated) {
                    setCheckVersionIntervalActive(false)
                    clearInterval(interval)
                }
                checkTimeLogifyVersionUpdated()
            }, 5000)
        }

        return () => clearInterval(interval)
    }, [checkVersionIntervalActive, timeLogifyUpdated])


    // Functions
    async function checkTimeLogifyVersionUpdated() {
        try {
            const version = await TimeLogifyAPI.getVersion()
            if (version === requestedVersion) {
                setTimeLogifyUpdated(true)
            }
        } catch (error) {
            setTimeLogifyUpdated(false)
        }
    }

    function handleDownloadInstaller() {
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = 'TimeLogifySetup.zip'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setInstallerDownloaded(true)
        setCheckVersionIntervalActive(true)

        handleContinueToStep(2)
    }

    function handleContinueToStep(step) {
        if (step > wizardStep)
            setWizardStep(step)

        setTimeout(() => {
            scrollToNextStep(step)
        }, 1);
    }

    function scrollToNextStep(step) {
        if (step === 1) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return
        }

        wizardSteps[step - 1].current.scrollIntoView({ behavior: 'smooth' })
    }


    // Layout
    if (loading) return <>
        <HeaderBar />
        <LoadingText />
    </>

    return <>
        <HeaderBar />

        <div className='setupWizard'>

            <div className='homeSection' ref={wizardRef1} >
                <div className='homeSubtitle'>✨ A new version is available!</div>
                <div className='homeSpacer' />
                <div className='homeSubtext'>Download the installer below to update TimeLogify and enjoy the latest features.</div>
                <div className='verticalSpacer' />
                <div className='verticalSpacer' />
                {releaseNotes[requestedVersion]?.map((note, i) => (
                    <div key={i} className='homeSubtext' style={{ color: 'var(--border-color)' }}>✅ {note}</div>
                ))}
                <div className='verticalSpacer' />
                <div className='verticalSpacer' />
                <div className='verticalSpacer' />
            </div>
            {
                installerDownloaded ?
                    <ModularButton
                        title='Continue'
                        onClick={() => handleContinueToStep(2)}
                        border={true}
                        iconEnd={<DownArrowsIcon size={24} />}
                        style={{ display: wizardStep >= 1 ? 'flex' : 'none' }}
                    />
                    : <>
                        <ModularButton
                            title='Download installer'
                            onClick={handleDownloadInstaller}
                            border={true}
                            iconEnd={<DownloadIcon size={24} />}
                        />
                        {requestedVersion === '0.9.16' &&
                            <>
                                <VerticalSpacer size={2} />
                                <div style={{ pointerEvents: 'none' }}>
                                    <iframe
                                        src="https://giphy.com/embed/1DxW9PPKONgi51i7zX"
                                        style={{ border: 'none', opacity: 0.5 }}
                                        frameBorder="0"
                                        className="giphy-embed"
                                        allowFullScreen
                                        title="NFL Gif"
                                    ></iframe>
                                </div>
                            </>
                        }
                    </>
            }

            <div className='homeSection' ref={wizardRef2} style={{ display: wizardStep >= 2 ? 'flex' : 'none' }}>
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />

                <div className='homeSubtitle'>{timeLogifyUpdated ? '✅ Update complete!' : '⏳ Almost there...'}</div>
                <div className='homeSpacer' style={{ width: !timeLogifyUpdated ? '14rem' : '21rem' }} />
                <div className='homeSubtext'>{timeLogifyUpdated ? 'TimeLogify was succesfully updated!' : 'Please open the installer to proceed with the installation.'}</div>
                {!timeLogifyUpdated && <div className='homeSubtext'>You might need to select '<span style={{ textDecoration: 'underline' }}>More info</span>' and then '<span style={{ fontWeight: '600' }}>Run anyway</span>' to run the installer.</div>}

                <div className='verticalSpacerLarge' />
            </div>
            {
                !timeLogifyUpdated ?
                    <ModularButton
                        title='Check again'
                        onClick={checkTimeLogifyVersionUpdated}
                        border={false}
                        iconEnd={<RefreshIcon size={24} />}
                        style={{ display: wizardStep >= 2 ? 'flex' : 'none' }}
                    />
                    :
                    <ModularButton
                        title='Continue'
                        onClick={() => navigate('/dashboard')}
                        border={true}
                        iconEnd={<RightArrowsIcon size={24} />}
                        style={{ display: wizardStep >= 2 ? 'flex' : 'none' }}
                    />
            }

            {(wizardStep > 1) && <>
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
            </>}
        </div>
    </>
}

export default UpdateWizard